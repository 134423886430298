import SectionHeading from "../SectionHeading"

const TokenInfo = ({img, dataUp, dataDown}) => {

    return (

        <div className=" section-padding-100-0" id="features">
            <div className="container">
                <SectionHeading
                    ClassSpanTitle="gradient-t green"
                    titleUp="About Our Token"
                    titleDown="HB - Hyperversal Blocks"
                    text="HB is the utility token that will be used across our ecosystem for rewards, in-app purchases or trade. Every project will have its own governance token but will use this as its utility token."
                />
                <div className="row align-items-center">
                    <div className="col-12 col-lg-5 col-md-12">
                        <img src={img} alt=""/>
                    </div>
                    <div className="col-12 col-lg-7 col-sm-12">
                        <div className="col-md-6 col-sm-6">
                            <div className="who-we-contant mt-s">
                                <ul className="token-information">
                                    {dataUp && dataUp.map((item, key) => (
                                        <li key={key}>
                                            <span className={item.Num}/>
                                            <h6>{item.title}</h6>
                                            <p className={`${item.isLast && "mb-0"}`}>{item.text}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="who-we-contant mt-s">
                                <ul className="token-information">
                                    {dataDown && dataDown.map((item, key) => (
                                        <li key={key}>
                                            <span className={item.Num}/>
                                            <h6>{item.title}</h6>
                                            <p className={`${item.isLast && "mb-0"}`}>{item.text}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TokenInfo;