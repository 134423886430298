import "./Subscribe.scss"
import react from "react";
import { useState } from "react";

const Subscribe = () => {
  const API_URL = 'https://av-mailer-3390a74d9d03.herokuapp.com'; // Update with your actual API endpoint
  
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const validateEmail = (inputEmail) => {
    // A simple email validation using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputEmail));
  };

  const sendEmail = async () => {
    try {
      if (!isValidEmail) {
        throw new Error('Invalid email format');
      }

      const response = await fetch(`${API_URL}/email/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      // Handle errors, such as invalid email format, network issues, or server errors.
      console.error('Error sending email:', error);
    }
  };

  const handleButtonClick = async () => {
    try {
      await sendEmail();
      // Optionally, you can handle success or redirect the user after sending the email.
      console.log('Email sent successfully!');
    } catch (error) {
      // Handle errors, such as invalid email format, network issues, or server errors.
      console.error('Error sending email:', error);
    }
  };

    return (

      <section className="container">
        <div className="subscribe section-padding-0-100 addMLInSmall">
          <div className="row"  >
            <div className="col-sm-12" >
              <div className="subscribe-wrapper" >
                <div className="section-heading text-center">
                  <h2 data-aos="fade-up" data-aos-delay="300">Don’t Miss News And Updates!</h2>
                  <p data-aos="fade-up" data-aos-delay="400">Enter your email below to get updates directly in your inbox.</p>
                </div>
                <div className="service-text text-center">
                  <div className="subscribe-section has-shadow">
                    <div className="input-wrapper">
                      <i className="fa fa-home" />
                      <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your Email" />
                    </div>
                    <button className="btn more-btn" onClick={handleButtonClick}>Subscribe</button>
                  </div>
                  {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}
      <br />
                </div>
              </div>
            </div>
          </div>
        </div>  
      </section>
    );
}

export default Subscribe