import SectionHeading from "../SectionHeading"
import "./Roadmap.scss"

const Roadmap = ({bmldata, avData , ClassSpanTitle}) => {

    return (
      <section className={`${ClassSpanTitle} addMLInSmall`} id="roadmap">
        <SectionHeading
          ClassSpanTitle="gradient-text blue"
          titleUp="MVP Development Roadmap for Averveil and BML-Loop"
          titleDown="BML-Loop Roadmap"
          text="This section highlights the roadmap for BML-Loop."
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-timeline">
                {bmldata && bmldata.map((item , key) => (
                  <div className="timeline" key={key}>
                    <div className="icon" />
                    <div className="date-content">
                      <div className="date-outer"> <span className="date"> <span className="month">{item.month}</span> <span className="year">{item.year}</span> </span>
                      </div>
                    </div>
                    <div className="timeline-content">
                      <h5 className="title">{item.title}</h5>
                      <p className="description text-light-gray">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
            <SectionHeading
                ClassSpanTitle="gradient-text blue"
                titleDown="Averveil Roadmap"
                text="This section highlights the roadmap for Averveil."
            />
        </div>
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="main-timeline">
                          {avData && avData.map((item , key) => (
                              <div className="timeline" key={key}>
                                  <div className="icon" />
                                  <div className="date-content">
                                      <div className="date-outer"> <span className="date"> <span className="month">{item.month}</span> <span className="year">{item.year}</span> </span>
                                      </div>
                                  </div>
                                  <div className="timeline-content">
                                      <h5 className="title">{item.title}</h5>
                                      <p className="description text-light-gray">{item.description}</p>
                                  </div>
                              </div>
                          ))}
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );

}

export default Roadmap