import {
    HaseebIMG,

    HomeDemo3Icons1,
    HomeDemo3Icons2,
    HomeDemo3Icons3,
    HomeDemo3TeamImg1,
    HomeDemo3TeamImg2,
    HomeDemo3TeamImg3,
    HomeDemo3TeamImg4,
    HyperverseLogo, IqraIMG, MultiverseLogo, OmerIMG

} from '../../../utils/allImgs'

export const WorkingsInfo = [
    {
        img:HyperverseLogo,
        num:1,
        title:"Hyperverse",
        text:"Ecosytem that allows connectivity with multi-verses and universes that are part of ecosystem."
    },
    {
        img:MultiverseLogo,
        num:2,
        title:"Multiverses",
        text:"Multiverse is a product built on top of Hyperverse that uses different smaller universes."
    },
    {
        img:HomeDemo3Icons2,
        num:3,
        title:"Universes",
        text:"Single product with a single focus or goal that may also behave as a reusable component."
    }
]

export const OurTeamInfo = [
    {
        img:OmerIMG,
        title:"Omer Nawaz",
        text:"Head of Marketing",
        in:"https://www.linkedin.com/in/omer-nawaz-243247228/"
    },
    {
        img:HaseebIMG,
        title:"Haseeb Asjad",
        text:"CTO",
        in:"https://www.linkedin.com/in/raadhhaseeb/"
    },
    {
        img:IqraIMG,
        title:"Iqra Nawaz",
        text:"UX/UI Designer",
        in:"https://www.linkedin.com/in/iqra-nawaz-aa043b219/"
    }
]