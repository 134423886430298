import {useEffect} from "react";
import {addRemoveClassBody} from '../../utils'

import {
    WorkingsInfo,
    OurTeamInfo
} from '../../data/data-containers/Home/data-Home.js';

import dataTokenInfoUp from '../../data/data-containers/Home/data-TokenInfoUp.json';
import dataTokenInfoDown from '../../data/data-containers/Home/data-TokenInfoDown.json';
import RoadmapBMLInfo from '../../data/data-containers/Home/dataBML-RoadmapInfo.json';
import RoadmapAVInfo from '../../data/data-containers/Home/dataAV-RoadmapInfo.json';
import FaqInfo from '../../data/data-containers/Home/data-FaqInfo.json';
import dataContact from "../../data/data-containers/Home/data-contact.json"
import IcoName from '../../data/data-layout/Footer/data-IcoName.json'
import TextFooter from '../../data/data-layout/Footer/data-TextFooter.json'

import devs from '../../assets/img/svg/devs.svg'

import {
    HomeDemo3Payment,
    HomeDemo3Whitepaper1,
    HomeDemo3Trade1,
    HomeDemo3Dist,
    HomeDemo3Faq,
    FooterPattern,
    FooterLogo, DocumentIco, Tokenomics, ImgFAQ,
} from '../../utils/allImgs'

import Header from "../../layouts/Header"
import Footer from '../../layouts/Footer'

import SecHeroSection from '../../components/HeroSection'
import AboutUs from '../../components/AboutUs'
import SecToken from '../../components/SecToken'
import OurICO from '../../components/OurICO'
import Roadmap from '../../components/Roadmap'
import TokenInfo from '../../components/TokenInfo'
import Workings from '../../components/Workings'
import Faq from '../../components/Faq'
import OurTeam from '../../components/OurTeam'
import Subscribe from '../../components/Subscribe'

const Home = () => {

    useEffect(() => {
        addRemoveClassBody('darker')
    }, [])

    return (
        <div>
            <Header Title="Home"/>
            <SecHeroSection
                ClassSec="hero-section moving section-padding"
                ColClass="col-12 col-lg-6 col-md-12"
                specialHead="Hyperversal Blocks"
                textUp="Building an array of decentralized products"
                textDown="Crypto is not just a speculative instrument for traders. Not on our watch. "
                link1="Whitepaper"
                link2="Discord"
                HomeDemo3_3={false}
            />
            <AboutUs
                ClassSec="about-us-area section-padding-100-0 clearfix"
                Photo={devs}
                Trade={null}
                titleUp="Why we matter?"
                titleDown="Our Ethos"
                textUp="We belive in Open-Source, Privacy Preserving and Decentralized Products."
                textDown="We, at Hyperversal Blocks believe that the best way forward is to research, plan and execute new features and services
            for the consumer."
                link="Read More"
            />
            <div className="clearfix"/>
            <Workings
                data={WorkingsInfo}
            />
            {/*<SecToken*/}
            {/*    conuterHeader="TOKEN PRE-SALE STARTS IN"*/}
            {/*    title="33m"*/}
            {/*    strength="75m"*/}
            {/*    pullLeft="Softcap in 103 days"*/}
            {/*    pullRight="Token Hardcap"*/}
            {/*    prePrice="Pre-Sale 1 ETH = 8,000 Token"*/}
            {/*    img={HomeDemo3Payment}*/}
            {/*/>*/}
            <Roadmap
                bmldata={RoadmapBMLInfo}
                avData={RoadmapAVInfo}
                ClassSpanTitle="roadmap section-padding-0-0"
            />

            <OurICO
                Whitepaper1={DocumentIco}
                Trade1={HomeDemo3Trade1}
                title="Our Whitepaper"
                textUp="Visit the link to Check our Whitepaper"
                textDown="This document contains all of the information required to understand our tokenomics or project briefs. This document is constantly being updated and maintained on github."
                link="Visit Now"
            />
            <TokenInfo
                img={Tokenomics}
                dataUp={dataTokenInfoUp}
                dataDown={dataTokenInfoDown}
            />
            <Faq
                ClassSpanTitle="faq-timeline-area section-padding-100-85"
                img={ImgFAQ}
                data={FaqInfo}
            />
            <OurTeam
                data={OurTeamInfo}
                ClassSec="our_team_area section-padding-0-0 clearfix"
            />
            <Subscribe/>
            <Footer
                FooterPattern={FooterPattern}
                FooterLogo={FooterLogo}
                text="Building an array of decentralized products."
                IcoName={IcoName}
                TextFooter={TextFooter}
                dataContact={dataContact}
            />
        </div>
    );
};

export default Home