import { NavLink } from "react-router-dom";

const SecHeader = ({Logo , dropdownItem , MenuInfo}) => {

    return (

      <nav className="navbar navbar-expand-lg navbar-white fixed-top" id="banner">
        <div className="container">
          {/* Brand */}
          <a className="navbar-brand" href="#"><span><img src={Logo} alt="logo" /></span></a>
          {/* Toggler/collapsible Button */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-expanded="false">
            <span className="navbar-toggler-icon" />
          </button>
          {/* Navbar links */}
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto">
              {/*<li className="nav-item dropdown">*/}
              {/*  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Home</a>*/}
              {/*  <div className="dropdown-menu" aria-labelledby="navbarDropdown">*/}
              {/*    {dropdownItem && dropdownItem.map((item , key) => (*/}
              {/*        <NavLink key={key} className="dropdown-item" to={item.path}>{item.nameLink}</NavLink>*/}
              {/*    ))}*/}
              {/*  </div>*/}
              {/*</li>*/}
              {MenuInfo && MenuInfo.map((item , key) => (
                <li className="nav-item" key={key}>
                  <a className="nav-link" href={item.path}>{item.nameLink}</a>
                </li>
              ))}
              <li className="lh-55px"><a href="https://discord.gg/hs6QbEfw" target="_blank" className="btn login-btn ml-50">Discord</a></li>
            </ul>
          </div>
        </div>
      </nav>
    );
}

export default SecHeader;