import React from 'react';
import './PrivacyPolicy.scss';
import Header from "../../layouts/Header";

const PrivacyPolicy = () => {
    return (
        <div>
            <div className="privacy-policy">

                <h1>Privacy Policy for Hyperversal Blocks</h1>

                <p>At Hyperversal Blocks, accessible from https://hyperversalblocks.com/, safeguarding the privacy of
                    our visitors is of utmost importance. This Privacy Policy document outlines the types of information
                    that is collected and recorded by Hyperversal Blocks and how we handle it.</p>

                <p>If you have any questions or need further information about our Privacy Policy, feel free to contact
                    us.</p>

                <p>This Privacy Policy pertains solely to our online activities and is valid for visitors to our website
                    concerning the information that they share and/or collect at Hyperversal Blocks. It doesn't address
                    information collected offline or through channels other than this website.</p>

                <h2>Consent</h2>

                <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms. We do not
                    store any of your data unless you explicitly provide us with consent to do so.</p>

                <h2>Information we collect</h2>

                <p>We will clearly inform you about the personal information you might be asked to provide and the
                    reasons for collecting such data at the time of the request.</p>
                <p>If you contact us directly, we might gather information such as your name, email address, phone
                    number, the content of any messages or attachments you send, and any other information you willingly
                    offer.</p>
                <p>However, when you register for an Account, while we might ask for details such as name, company name,
                    address, email address, and telephone number, we will only store this data with your explicit
                    consent.</p>

                <h2>How we use your information</h2>

                <p>We use the information we collect in various ways, such as:</p>

                <ul>
                    <li>Providing, operating, and maintaining our website</li>
                    <li>Enhancing, personalizing, and expanding our website</li>
                    <li>Understanding and analyzing your use of our website</li>
                    <li>Developing new products, services, features, and functionalities</li>
                    <li>Communicating directly or through our partners, including for customer service, updates, and
                        promotional purposes
                    </li>
                    <li>Sending you emails</li>
                    <li>Detecting and preventing fraud</li>
                </ul>

                <h2>Log Files</h2>

                <p>Like many other websites, Hyperversal Blocks uses log files as a standard procedure. These files log
                    visitors when they visit websites. However, the data in these log files, including IP addresses,
                    browser types, ISPs, timestamps, and the like, is not linked to any personally identifiable
                    information. We utilize this data primarily for trend analysis, site administration, and broad
                    demographic information gathering.</p>

                <h2>Cookies and Web Beacons</h2>

                <p>Hyperversal Blocks uses "cookies" like most websites. These cookies capture information such as
                    visitor preferences and visited pages on our site. This data helps optimize the user experience by
                    tailoring our web content based on visitors' browser type and other metrics.</p>

                <h2>Advertising Partners Privacy Policies</h2>

                <p>We advise you to review the Privacy Policies of our advertising partners to get a comprehensive
                    understanding of their practices and how they handle data. Remember, Hyperversal Blocks does not
                    have control over the cookies deployed by third-party advertisers.</p>

                <h2>Third Party Privacy Policies</h2>

                <p>This Privacy Policy does not extend to other advertisers or websites. Hence, we recommend reviewing
                    the respective privacy policies of third-party servers for more information.</p>

                <p>For details on managing cookies with specific browsers, you can find it on the respective browser's
                    website.</p>

                <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                <p>California consumers under the CCPA have specific rights, which include:</p>

                <h2>GDPR Data Protection Rights</h2>

                <p>We are committed to ensuring that you are aware of your data protection rights. Every user is
                    entitled to the following:</p>

                <h2>Children's Information</h2>

                <p>We prioritize the safety of children online. We urge parents and guardians to oversee, participate
                    in, and monitor their children's online activity.</p>

                <p>Hyperversal Blocks does not intentionally collect any Personal Identifiable Information from children
                    under 13. If you believe your child has provided such information, please contact us so we can
                    promptly remove it from our records.</p>

                <h2>Changes to This Privacy Policy</h2>

                <p>We might occasionally update our Privacy Policy. We advise regular checks on this page for updates.
                    Any changes will be effective immediately upon posting on this page.</p>

                <h2>Contact Us</h2>

                <p>If you have questions or suggestions about our Privacy Policy, please contact us.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
