import "./AboutUs.scss"

const AboutUs = ({
  ClassSec="about-us-area section-padding-0-100 addMLInSmall clearfix",
  Photo,
  Trade,
  titleUp,
  titleDown,
  textUp,
  textDown,
  link
}) => {

  return (

    <section className={ClassSec} id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
            <div className="welcome-meter">
              <img draggable="false" className="comp-img" src={Photo} alt="" />
              {/*<img draggable="false" className="supportImg floating-anim" src={Trade} alt="image" />*/}
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 mt-s">
            <div className="who-we-contant">
              <div className="dream-dots text-left fadeInUp" data-aos="fade-up">
                <span className="gradient-text">{titleUp}</span>
              </div>
              <h4 className="fadeInUp" data-aos="fade-up">{titleDown}</h4>
              <p className="fadeInUp" data-aos="fade-up">{textUp}</p>
              <p className="fadeInUp" data-aos="fade-up">{textDown}</p>
              <a className="btn more-btn mt-15 fadeInUp" data-aos="fade-up" href="https://hyperversal-blocks.gitbook.io/hyperversal-blocks/" target="_blank" rel="noopener noreferrer">{link}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;