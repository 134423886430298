import SectionHeading from "../SectionHeading"
import "./Team.scss"

const OurTeam = ({data , ClassSec}) => {

    return (

      <section className={`${ClassSec} addMLInSmall`} id="team">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionHeading
                ClassSpanTitle="gradient-text blue"
                // titleUp="Our Team"
                titleDown="Our Team"
                text="Our team that is actively working on this project."
              />

            </div>
          </div>
          <div className="row">
            {data && data.map((item , key) => (
                <div className="col-12 col-sm-6 col-lg-4" key={key}>
                  <div className="single-team-member" data-aos="fade-up">
                    {/* Image */}
                    <div className="team-member-thumb">
                      <img src={item.img} className="center-block" alt="" />
                    </div>
                    {/* Team Info */}
                    <div className="team-info">
                      <h5 className="w-text">{item.title}</h5>
                      <p className="g-text">{item.text}</p>
                    </div>
                    {/* Social Icon */}
                    <div className="team-social-icon">
                      <a href={item.in} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" /></a>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </section>
    );
}

export default OurTeam